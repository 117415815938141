@import url('../../config.css');

.root {
    background: var(--white);
    border: 1px solid var(--textLight);
    border-radius: 0.5rem;
    box-shadow: 2px 5px 10px var(--textLight);
    display: none;
    min-height: 150px;
    padding: 1rem;
    position: sticky;
    transition: var(--transitionTime);
    top: 114px;

    &.isModal {
        box-shadow: none;
        border-radius: 0;
        display: block;
        height: 100vh;
        left: 0;
        overflow-y: scroll;
        position: fixed;
        padding: 0;
        top: 0;
        width: 100%;
        z-index: var(--zIndexModal);

        @media (--viewportMedium) {
            border-radius: 0.5rem;
            height: unset;
            left: calc((100vw - var(--maximumViewWidth)) / 2);
            max-width: calc(var(--maximumViewWidth) - 4rem);
            overflow-y: unset;
            top: 50%;
            transform: translateY(-50%);
            padding: 2rem;
            width: 100%;
            z-index: var(--zIndexModal);
        }
    }

    &.isSent {
        left: calc((100vw - var(--maximumViewWidth) / 2) / 2);
        max-width: calc((var(--maximumViewWidth) - 4rem) / 2);
    }

    @media (--viewportMedium) {
        display: block;
    }
}

.panelHeader {
    color: var(--brandColor);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem;

    @media (--viewportMedium) {
        display: block;
        flex-direction: unset;
        padding: 0 0 1rem 0;
    }
}
.mobileCloseButton {
    background: transparent;
    border: 0;
    font-size: 1.25rem;

    @media (--viewportMedium) {
        display: none;
    }
}
.form {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;

    @media (--viewportMedium) {
        flex-direction: row;
        padding-bottom: 0;
    }
}
.dates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 1rem 0;
}
.dateFieldWrapper {
    flex-grow: 1;
    position: relative;

    &:first-child {
        margin-right: 0.25rem;
    }
    &:last-child {
        margin-left: 0.25rem;
    }
}
.dateLabel {
    cursor: pointer;
    display: block;
    font-size: 0.65rem;
    font-weight: var(--fontWeightBold);
    left: 0;
    padding: 0.5rem;
    position: absolute;
    text-transform: uppercase;
    top: 0;
}

.dateIcon {
    color: var(--brandColor);
    font-size: 1rem;
    line-height: 3.5rem;
    margin: 0 0.5rem;
    vertical-align: middle;
}

.dateValue {
    align-items: center;
    border: 1px solid var(--textLight);
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 1.25rem;
    line-height: 2.5rem;
    min-height: 2.5rem;
    padding-top: 1rem;
    text-align: center;
    vertical-align: bottom;

    &.noDateSelected {
        padding-top: 0;
        line-height: 3.5rem;
        min-height: 3.5rem; 

        .dateIcon {
            color: var(--textMedium);
        }

        &:hover {
            background: var(--backgroundVeryLight);
        }
    }
}
.breakdown {
    padding: 1.5rem;

    @media (--viewportMedium) {
        padding: 0;
        width: 100%;
    }
}
.details {
    display: none;
    padding-left: 2rem;
    width: 75%;
}

.lineItems {
    margin: 0;
    padding: 1rem 0 0 0;
}
.deposit,
.lineItemTotal,
.lineItem {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 2rem;
    vertical-align: middle;
}
.discount {
    color: var(--success);
}
.lineItemTotal, .deposit {
    border-top: 1px solid var(--textLight);
    font-weight: var(--fontWeightBold);
    margin-top: 1rem;
    padding: 1rem 0 1rem 0;
}

.deposit {
    border-top: 0;
    font-weight: var(--fontWeightRegular);
    margin-top: 0;
    padding-top: 0;
}

.depositLabelWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.infoIcon {
    margin-right: 0.5rem;
}

.fieldWrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
}
.fieldLabel {
    color: var(--textMediumDark);
    font-size: 1rem;
    font-weight: var(--fontWeightBold);
    padding: 0 0 0.5rem 0;
}
.field {
    background: var(--backgroundSuperLight);
    border: 0;
    border-bottom: 2px solid var(--textLight);
    font-size: 1.25rem;
    line-height: 1rem;
    padding: 0.5rem;
    vertical-align: middle;

    &::placeholder {
        color: var(--textLight);
    }

    &:focus, &:hover {
        background: var(--backgroundLightest);

        outline: none;
    }
}
textarea.field {
    height: 5rem;
    max-width: 100%;
}

.button, .buttonClose {
    background-color: var(--brandColor);
    border: 0;
    border-radius: 0.25rem;
    color: var(--textDark);
    cursor: pointer;
    font-size: 1rem;
    font-weight: var(--fontWeightBold);
    padding: 1rem;
    text-transform: uppercase;
    width: 100%;

    &:disabled, &:disabled:hover {
        background: var(--backgroundLight);
        box-shadow: none;
        color: var(--textMedium);
        cursor: not-allowed;
    }

    &:hover {
        box-shadow: 2px 5px 10px var(--textLight);
    }

    &:focus {
        outline: none;
    }
}
.buttonClose {
    display: none;
    margin-top: 1rem;
}

.tooltip {
    margin-left: 0.5rem;
}

.isModal {
    .buttonClose {
        display: inline-block;
    }

    .dates {
        border-bottom: 1px solid var(--textLight);
        flex-direction: column;
        padding: 0 0 1rem 0;
    }

    .dateFieldWrapper {
        display: flex;
        flex-direction: column;
        
        @media(--viewportMedium) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .dateValue {
        border: 0;
        color: var(--textDark);
        cursor: unset;
        display: inline-block;
        flex-grow: 1;
        font-size: 1rem;
        font-weight: var(--fontWeightBold);
        line-height: 2rem;
        padding: 0;
        text-align: left;
        vertical-align: middle;
        width: unset;

        @media(--viewportMedium) {
            text-align: right;
        }
    }

    .dateLabel {
        font-size: 1rem;
        font-weight: var(--fontWeightRegular);
        line-height: 2rem;
        position: relative;
        padding: 0;
        text-transform: none;
        vertical-align: middle;
    }

    .button {
        display: none;
    }

    .breakdown {
        padding: 0 1.5rem;

        @media (--viewportMedium) {
            flex-grow: 1;
            max-width: 40%;
        }
    }

    .details {
        display: unset;
        max-width: unset;
        padding: 0 1.5rem;
        width: calc(100% - 3rem);

        @media (--viewportMedium) {
            max-width: 60%;
        }

        .button {
            display: inline-block;
        }
    }
}

.checkGroupWrapper {
    display: flex;
    flex-direction: column;
}

.checkboxWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
}
.termsAndConditions {
    padding: 1rem 0 2rem 0;
}
.termsAndConditionsLink {
    color: var(--textDark);
    transition: var(--transitionTime);

    &:hover {
        color: var(--textMediumDark);
    }
}
