@import url('../../config.css');

.root {
    display: flex;
    flex-direction: column;
    position: relative;
}
.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    max-height: calc(100vw * 0.825);
    overflow-y: hidden;
    padding: 0;
    transition: var(--transitionTime);

    @media (--viewportMedium) {
        max-height: unset;
    }
}
.item {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    line-height: 2.25rem;
    list-style-type: none;
    width: 100%;

    @media (--viewportMedium) {
        width: 50%;
    }
}
.icon {
    margin: 0.5rem 0.75rem 0 0;
    min-width: 1.5rem;
}
.label {
    flex-grow: 1;
}

.overlay {
    align-items: flex-end;
    background: linear-gradient(transparent calc(100vw * 0.3), var(--white));
    display: flex;
    flex-direction: row;
    height: 100%;
    position: absolute;
    width: 100%;
    text-align: center;

    @media (--viewportMedium) {
        display: none;
    }
}

.more {
    background: transparent;
    border: 0;
    border-radius: 0.25rem;
    color: var(--brandColor);
    cursor: pointer;
    font-size: 1rem;
    font-weight: var(--fontWeightBold);
    line-height: 2rem;
    padding: 0 2rem;
    margin: 0 auto;
    text-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.3),
        4px 4px rgba(0, 0, 0, 0.2),
        5px 5px rgba(0, 0, 0, 0.1);
}

.expanded {
    .list {
        max-height: unset;
    }
    .overlay {
        display: none;
    }
}