@import url('../../config.css');

.instructions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.instructions li {
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
}
.description {
    flex-grow: 1;
    font-size: 1rem;

    @media (--viewportMedium) {
        flex-grow: 0;
        font-size: 1.25rem;
        height: 50%;
        text-align: center;
    }
}