@import url('./config.css');

html {
    font-size: 16px;
}
body, input, textarea, button {
    font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

.root {
    position: relative;
}

.sectionHeader {
    margin-top: 6rem;
    font-size: 1.5rem;
    font-weight: var(--fontWeightRegular);
    padding-bottom: 2rem;
    text-transform: uppercase;

    @media (--viewportMedium) {
        font-size: 2rem;
        margin-top: 0;
    }
}

.about {
    padding: 2rem var(--contentWidthPadding);
}

.features {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem var(--contentWidthPadding) 4rem var(--contentWidthPadding);
}

.photos {
    background: var(--backgroundVeryLight);
    padding: 2rem calc((100vw - var(--maximumViewWidth)) / 2) 6rem calc((100vw - var(--maximumViewWidth)) / 2);
}

.pricing {
    padding: 2rem calc((100vw - var(--maximumViewWidth)) / 2) 6rem calc((100vw - var(--maximumViewWidth)) / 2);
}

.contentWrapper {
    display: flex;
    flex-direction: row;
}

.serviceDetails {
    display: flex;
    flex-direction: row;
}

.serviceContent {
    width: 100%;

    @media (--viewportLarge) {
        padding-right: 2rem;
        width: calc(66% - 2rem);
    }
}

.sideBar {
    @media (--viewportLarge) {
        display: block;
        padding-bottom: 4rem;
        padding-left: 2rem;
        position: relative;
        width: calc(33% - 2rem);
    }
}

.details {
    padding: 0 1.5rem;

    @media (--viewportMedium) {
        padding: 2rem var(--contentWidthPadding);
    }

}
.detailContent {
    display: flex;
    flex-direction: row;
}
.description {
    width: 75%
}
.availability {
    width: 25%;
}


.book {
    padding: 2rem calc((100vw - var(--maximumViewWidth)) / 2) 6rem calc((100vw - var(--maximumViewWidth)) / 2);
}

.map {

}

:global(.mapboxgl-ctrl-attrib-button) {
    display: none;
}

:global(.mapboxgl-ctrl-attrib-inner) {
    text-align: center;

    & a {
        color: var(--textLigth);
    }
}

.contactDetails {
    padding: 4rem calc((100vw - var(--maximumViewWidth)) / 2) 6rem calc((100vw - var(--maximumViewWidth)) / 2);
}

.overlay {
    background: rgba(0, 0, 0, 0.6);
    display: none;
    height: calc(100vh * 10);
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: var(--zIndexOverlay);
}

.withModal {
    overflow: hidden;
}

.withModal .overlay {
    display: block;
}

.termsAndConditions {
    background: var(--white);
    height: 100vh;
    overflow: scroll;
    padding: 0 calc((100vw - var(--maximumViewWidth)) / 2);
    position: fixed;
    top: calc(100vh * -1 - 11rem);
    transition: var(--transitionTime);
    width: calc(100vw - (100vw - var(--maximumViewWidth)));
    z-index: var(--zIndexTooltip);

    &.show {
        top: 0;
    }
}
