@import url('../../config.css');

.root {
    align-items: flex-end;
    background: var(--white);
    display: flex;
    flex-direction: column;
    height: 5rem;
    position: absolute;
    right: 0;
    width: 100vw;

    @media (--viewportMedium) {
        align-items: center;
        flex-direction: row;
        position: relative;
    }
}

.navigation {
    background: var(--white);
    border-top: 1px solid var(--textLight);
    display: flex;
    flex-direction: column;
    transition: var(--transitionTime);
    height: 100vh;
    position: absolute;
    top: -100vh;
    width: 100vw;

    @media (--viewportMedium) {
        justify-content: flex-end;
        border-top: 0;
        flex-direction: row;
        flex-grow: 1;
        height: unset;
        position: relative;
        top: unset;
        width: unset;
    }
}

.mobileNavigationWrapper {
    background: var(--white);
    height: 5rem;
    padding-right: 1rem;
    padding-top: 1rem;
    position: relative;
    text-align: right;
    width: 100vw;
    z-index: calc(var(--zIndexTop) + 2);

    @media (--viewportMedium) {
        display: none;
    }
}

.mobileMenuButton {
    background: none;
    border: 0;
    font-size: 1.5rem;
    padding: 0.5rem;

    &:focus {
        outline: none;
    }
}

.isMobileNavigationOpen {
    .navigation {
        top: 5rem;
    }
}

.link {
    border-bottom: 1px solid var(--textLight);
    border-top: 0.25rem solid transparent;
    color: var(--textDark);
    cursor: pointer;
    font-size: 1.25rem;
    line-height: 3.5rem;
    opacity: 0.8;
    padding: 0 1.5rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: var(--transitionTimeQuick);

    @media (--viewportMedium) {
        border-bottom: 0.25rem solid transparent;
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 0 1rem;
        padding: 0;

        &:hover {
            border-bottom: 0.25rem solid var(--brandColor);
        }
    }
}