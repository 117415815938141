@import url('../../config.css');

.root {
    padding: 1.5rem 1.5rem 0 1.5rem;
    width: calc(100vw - 3rem);

    h1 {
        color: var(--brandColor);
        font-size: 1.5rem;
        margin-bottom: 2rem;

        @media (--viewportMedium) {
            font-size: 2rem;
        }

    }
    h2 {
        margin-bottom: 1rem;
    }

    p {
        line-height: 1.25rem;
    }

    @media (--viewportMedium) {
        padding: 4rem 0;
        width: unset;
    }
}

.controls {
    background: linear-gradient(transparent 0.25rem, var(--white));
    bottom: 0;
    padding: 1.5rem 0;
    position: sticky;
    width: 100vw;

    @media (--viewportMedium) {
        padding: 2rem 0;
        width: 100%;
    }

    button {
        background: var(--brandColor);
        border: 0;
        border-radius: 0.25rem;
        cursor: pointer;
        display: block;
        font-size: 1rem;
        font-weight: var(--fontWeightBold);
        padding: 0.5rem 1rem;
        width: calc(100vw - 3rem);

        @media (--viewportMedium) {
            font-size: 1.25rem;
            width: 100%;
        }

        &:hover {
            background: var(--backgroundDark);
            box-shadow: var(--boxShadowButton);
            color: var(--white);
        }

        &:focus {
            outline: none;
        }
    }
}