@import url('../../config.css');

.root {
    display: flex;
    flex-direction: row;
}

.content {
    width: 100%;

    @media (--viewportLarge) {
        padding-right: 2rem;
        width: calc(66% - 2rem);
    }
}

.sideBar {
    @media (--viewportLarge) {
        display: block;
        padding-bottom: 4rem;
        padding-left: 2rem;
        position: relative;
        width: calc(33% - 2rem);
    }
}   
