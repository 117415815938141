@import url('../../config.css');

.root {
    color: var(--info);
    cursor: help;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (--viewportMedium) {

    }
}

.tooltip {
    background: var(--infoBackground);
    border-radius: 0.5rem;
    box-shadow: 5px 5px 10px var(--textMediumDark);
    color: var(--info);
    display: none;
    line-height: 1.5rem;
    left: 0;
    padding: 1rem;
    position: absolute;
    top: 1.5rem;
    width: 300px;
    z-index: var(--zIndexTooltip);

    @media (--viewportMedium) {
        left: unset;
        top: 1.5rem;
    }

    &.visible {
        display: block;
    }
}