@import url('../../config.css');

.root {
    background-color: var(--backgroundVeryLight);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem 0;
    text-align: center;
    font-size: 0.8rem;
    text-transform: uppercase;

    @media (--viewportMedium) {
        flex-direction: row;
        margin-top: 5rem;
        padding: 4rem 0;
    }
}

.copyright {
    display: block;
    font-size: 0.5rem;

    @media (--viewportMedium) {
        color: var(--textDark);
        font-size: 0.8rem;
        margin-right: 2rem;
    }
}

.toTop {
    cursor: pointer;
    display: none;
    font-size: 0.8rem;

    @media (--viewportMedium) {
        display: unset;
    }
}
.terms {
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--textDark);
    font-size: 0.8rem;
    text-transform: uppercase;

    @media (--viewportMedium) {
        margin-left: 2rem;
    }

    &:focus {
        outline: none;
    }
}