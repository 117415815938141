@import url('../../config.css');

.root {
    border-bottom: 0 !important;
    margin: 0;
    padding: 1.5rem !important;

    @media (--viewportMedium) {
        margin: 0;
        padding: 4rem calc((100vw - var(--maximumViewWidth)) / 2) 6rem calc((100vw - var(--maximumViewWidth)) / 2) !important;
    }
}

.content {
    display: flex;
    flex-direction: column;

    @media (--viewportMedium) {
        flex-direction: row;
        line-height: 2rem;
    }
}

.address {
    flex-grow: 1;

    @media (--viewportMedium) {
        width: 33%;
    }
}

.contact {
    flex-grow: 1;

    @media (--viewportMedium) {
        width: 33%;
    }

    & strong {
        color: var(--textMediumDark);
        font-size: 0.75rem;
        text-transform: uppercase;
    }

    & a {
        color: var(--textMediumDark);
        font-weight: var(--fontWeightBold);
        text-decoration: underline;
        transition: 0.4s;

        &:hover {
            color: var(--textDark);
            text-decoration: none;
        }
    }

}

.social {
    flex-grow: 1;

    @media (--viewportMedium) {
        text-align: right;
        width: 33%;
    }
}

.facebookButton {
    background: var(--infoBackground) url('./facebook.svg') left 1rem center no-repeat;
    background-size: 24px 24px;
    border: 1px solid var(--textLight);
    border-radius: 0.5rem;
    color: var(--textDark);
    cursor: pointer;
    display: block;
    font-size: 1rem;
    font-weight: var(--fontWeightRegular);
    line-height: 20px;
    margin: 2rem 0 0 0;
    padding: 1rem 1rem 1rem 3rem;
    text-decoration: none;

    @media (--viewportMedium) {
        display: unset;
        width: unset;
    }

    &:hover {
        box-shadow: 2px 5px 10px var(--textLight);
    }
}