@import url('../../config.css');

.root {
    overflow: hidden;

    & :global(.DayPicker),
    & :global(.DayPicker) > div {
        outline: none;
        width: 100% !important;
    }

    & :global(.DayPicker) > div > div {
        outline: none;
        padding-bottom: 4rem;
        position: relative;
        width: 100% !important;
    }

    /**
     * Controls
     */

    & :global(.DayPickerNavigation) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: absolute;
        top: 1rem;
        width: 100%;
        z-index: var(--zIndexContentControls);
    }

    & :global(.DayPickerNavigation_rightButton__horizontalDefault),
    & :global(.DayPickerNavigation_leftButton__horizontalDefault) {
        cursor: pointer;
        height: 16px;
        outline: none;
        width: 16px;
    }

    & :global(.DayPickerKeyboardShortcuts_buttonReset) {
        display: none;
    }

    /**
     * Weekday headers
     */

    & :global(.DayPicker_weekHeaders) {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 4rem;
        width: 100%;
    }

    & :global(.DayPicker_weekHeader) {
        flex-grow: 1;
        padding: 0 !important;

        &:first-child {
            margin-right: 1rem;
        }

        &:nth-child(2) {
            margin-left: 1rem;
        }
    }

    & :global(.DayPicker_weekHeader_ul) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    & :global(.DayPicker_weekHeader_li) {
        font-size: 1rem;
        font-weight: var(--fontWeightBold);
        list-style-type: none;
        text-align: center;
        width: calc(100% / 7) !important;
    }

    /**
     * Calendar grid
     */

    & :global(.DayPicker_transitionContainer) {
        height: 370px !important;
        width: 100% !important;

        @media (--viewportMedium) {
            height: 300px !important;
        }
    }

    & :global(.CalendarMonthGrid) {
        display: flex;
        flex-direction: row;
        max-width: 100%;
    }

    & :global(.CalendarMonthGrid_month__horizontal) {
        width: 50% !important;

        &:nth-child(2) {
            margin-right: 1rem;
        }

        &:nth-child(3) {
            margin-left: 1rem;
        }
    }

    & :global(.CalendarMonthGrid_month__hidden) {
        display: none;
    }

    & :global(.CalendarMonth) {
        padding: 0 !important;
    }

    & :global(.CalendarMonth_table) {
        border-collapse: collapse;
        margin: 2rem 0 0 0;
        width: calc(100vw - 4rem);

        @media (--viewportMedium) {
            width: 100% !important;
        }
    }

    & :global(.CalendarMonth_table tr) {
        height: 2rem !important;
        border-bottom: 0.5rem solid transparent;
    }

    /**
     * Month header
     */

    & :global(.CalendarMonth_caption) {
        color: var(--textDark);
        padding: 1rem 0 1.5rem 0;
        text-align: center;

        & strong {
            font-weight: var(--fontWeightRegular);
        }
    }

    /**
     * Daily level
     */

    & :global(.CalendarDay) {
        border: 0;
        flex-grow: 1;
        height: calc((100vw - 2rem) / 7) !important;
        line-height: 2rem !important;
        margin: 0;
        padding: 0;
        text-align: center;
        vertical-align: middle;
        width: calc((100vw - 2rem) / 7) !important;

        @media (--viewportMedium) {
            height: 2rem !important;
            line-height: unset;
            width: unset;
        }

        &:focus {
            outline: none;
        }
    }

    & :global(.CalendarDay__blocked_calendar) {
        & .dayContents {
            color: var(--textMedium);
            text-decoration: line-through;

            &:hover {
                background-color: transparent;
                color: var(--textMedium);
                cursor: not-allowed;
                font-weight: var(--fontWeightRegular);
            }
        }
    }

    & :global(.CalendarDay__hovered_span),
    & :global(.CalendarDay__selected_span) {
        & .dayContents {
            background-color: var(--brandColor);
            border-radius: 1rem;
            color: var(--textDark);
            font-weight: var(--fontWeightBold);
            display: block;
            transition: 0s;
            margin: 0 auto;
            /* width: 100%; */
        }
    }

    & :global(.CalendarDay__hovered_span:hover),
    & :global(.CalendarDay__selected_end),
    & :global(.CalendarDay__selected_start) {
        & .dayWrapper {
            /* background: linear-gradient(to left, var(--branColorLight) 50%, var(--white) 50%); */
            display: block;
            height: 2rem;
        }

        & .dayContents {
            background-color: var(--brandColor);
            border-radius: 1rem;
            color: var(--textDark);
            font-weight: var(--fontWeightBold);
        }
    }

    & :global(.CalendarDay__hovered_span:hover),
    & :global(.CalendarDay__selected_end) {
        text-align: center;

        & .dayWrapper {
            /* background: linear-gradient(to right, var(--branColorLight) 50%, var(--white) 50%); */
        }
    }

    & :global(.CalendarDay__hovered_span:hover) {
        & .dayContents {
            position: relative;
            /* left: calc(50% - 1rem); */
            /* width: 2rem !important; */
        }
    }
}

.dayContents {
    border-radius: 1rem;
    display: inline-block;
    height: 2rem;
    line-height: 2rem;
    transition: var(--transitionTimeQuick);
    vertical-align: middle;
    width: 2rem;

    &:hover {
        background-color: var(--backgroundDark);
        color: var(--white);
        cursor: pointer;
        font-weight: var(--fontWeightBold);
    }

    &.dayBooked {
        background-color: var(--backgroundMedium);
        text-decoration: none !important;
    }
}

.button {
    background-color: var(--brandColor);
    border: 0;
    border-radius: 0.25rem;
    color: var(--textDark);
    cursor: pointer;
    font-size: 1rem;
    font-weight: var(--fontWeightBold);
    padding: 1rem;
    text-transform: uppercase;
    width: 100%;

    &:disabled, &:disabled:hover {
        background: var(--backgroundLight);
        box-shadow: none;
        color: var(--textMedium);
        cursor: not-allowed;
    }

    &:hover {
        box-shadow: 2px 5px 10px var(--textLight);
    }

    &:focus {
        outline: none;
    }
}

.mobileControls {
    padding-top: 2rem;

    @media (--viewportMedium) {
        display: none;
    }
}