@import url('../../config.css');

.root {
    margin-bottom: 1rem;
}

.title {
    font-size: 1.25rem;
}

.subTitle {
    font-size: 1rem;
    font-style: italic;
    font-weight: var(--fontWeightRegular);
}