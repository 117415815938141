@import url('../../config.css');

.carousel img {
    max-height: 100vh !important;
}

.thumbnails {
    display: flex;
    flex-flow: row nowrap;
    overflow-x: hidden;
    margin: 0;
    padding: 0;

    @media (--viewportMedium) {
        flex-flow: row wrap;
    }
}

.thumbnail {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    display: none;
    height: calc(100vw - 3rem);
    list-style-type: none;
    margin: 0;
    padding: 0;
    min-width: calc(100vw - 3rem);
    width: calc(100vw - 3rem);

    @media (--viewportMedium) {
        display: unset;
        height: calc(750px / 4.5);
        margin: 0 1.25rem 1.25rem 0;
        min-width: unset;
        width: calc(750px / 4.5);
    }

    &:hover {
        box-shadow: 2px 5px 10px var(--textLight);
    }
}

.thumbnailSideScrollActive {
    display: unset;
}

.dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem 0 0 0;
    padding: 0;


    @media (--viewportMedium) {
        display: none;
    }
}

.dot {
    background: var(--branColorLight);
    border-radius: 0.25rem;
    cursor: pointer;
    height: 0.5rem;
    line-height: 0.5rem;
    list-style-type: none;
    margin: 0 0.25rem;
    width: 0.5rem;

    &:last-child {
        margin-right: 0;
    }

    &.dotActive {
        background: var(--brandColor);
    }
}