@import url('../../config.css');

.root {
    background: var(--white);
    box-shadow: 0 0 10px var(--backgroundMedium);
    color: var(--textLight);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: var(--fontSizeH2);
    font-weight: var(--fontWeightBold);
    left: 0;
    max-width: var(--maximumViewWidth);
    padding: 0 calc((100vw - var(--maximumViewWidth)) / 2);
    position: fixed;
    top: 0;
    transition: 0.4s;
    width: 100%;
    z-index: var(--zIndexTop);

    @media (--viewportMedium) {
        border-bottom: 0.75rem solid var(--white);
        border-top: 0.75rem solid var(--white);
    }
}

.brand {
    background: var(--white);
    border-bottom: 0.75rem solid var(--white);
    border-top: 0.75rem solid var(--white);
    display: flex;
    flex-direction: row;
    margin-left: 1.5rem;
    position: relative;
    text-decoration: none;
    z-index: calc(var(--zIndexTop) + 3);

    @media (--viewportMedium) {
        border-bottom: 0;
        border-top: 0;
        margin-left: 0;
    }
}

.brandName {
    color: var(--brandColor);
    display: inline-block;
    font-family: 'Pacifico', cursive;
    font-size: 2rem;
    letter-spacing: 2px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.5),
        2px 2px rgba(0, 0, 0, 0.4),
        3px 3px rgba(0, 0, 0, 0.3),
        4px 4px rgba(0, 0, 0, 0.2),
        5px 5px rgba(0, 0, 0, 0.1);

    @media (--viewportMedium) {
        font-size: 2.5rem;
    }

}

.hasScrolled {
    @media (--viewportMedium) {
        border-bottom-width: 0.25rem;
        border-top-width: 0.25rem;
    }
}