.root {

}

.field {
    margin-right: 1rem;
}

.fieldLabel {
    cursor: pointer;
    line-height: 2rem;
}