@import url('../../config.css');

.root {
    border-bottom: 0;
    padding: 0 var(--contentWidthPadding) 1rem var(--contentWidthPadding);
    margin: 0 1.5rem;
    position: relative;

    @media (--viewportMedium) {
        margin: 0;
    }
}

.mapContainer {
    height: 100vw;
    padding: 1rem 0;

    @media (--viewportMedium) {
        height: 600px;
    }

    & canvas {
        max-height: calc(100vw - 3rem);
        max-width: calc(100vw - 3rem);

        @media (--viewportMedium) {
            max-height: unset;
            max-width: var(--maximumViewWidth);
        }
    }
}

.controlButton {
    background-color: var(--brandColor);
    border: 0;
    border-radius: 0.25rem;
    color: var(--textDark);
    cursor: pointer;
    display: inline-block;
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: var(--fontWeightBold);
    margin: 0 0.75rem 0 0;
    padding: 0.5rem;
    text-transform: uppercase;
    width: 2.5rem;

    &:hover {
        box-shadow: 2px 5px 10px var(--textLight);
    }

    &:focus {
        outline: none;
    }
}

.mapControls {
    background-color: var(--backgroundLight);
    top: 3.5rem;
    padding: 0.75rem 0;
    position: absolute;
    text-align: right;
    width: calc(100vw - 3rem);

    @media (--viewportMedium) {
        width: var(--maximumViewWidth);
    }
}