@import url('../../config.css');

.highlights {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.highlight {
    align-items: center;
    border: 1px solid var(--textLight);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: calc(0.02 * 100vw);
    min-height: 4rem;
    min-width: 4rem;
    padding: 0.25rem;
    width: 100%;
    white-space: pre-line;

    @media (--viewportMedium) {
        flex-direction: column;
        height: 8.5rem;
        padding: 1rem;
        width: 8.5rem;
    }
}
.icon {
    font-size: 2rem;
    margin: 0;
    min-width: 5rem;

    @media (--viewportMedium) {
        font-size: 1.5rem;
        height: calc(50% - 1.5rem);
        padding-bottom: 0.5rem;
        padding-top: 1rem;
    }
}
.description {
    flex-grow: 1;
    font-size: 1rem;

    @media (--viewportMedium) {
        flex-grow: 0;
        font-size: 1.25rem;
        height: 50%;
        text-align: center;
    }
}