@import url('../../config.css');

.root {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
}

.fieldLabel {
    color: var(--textMediumDark);
    font-size: 1rem;
    font-weight: var(--fontWeightBold);
    padding: 0 0 0.5rem 0;
}
.field {
    background: var(--backgroundSuperLight);
    border: 0;
    border-bottom: 2px solid var(--textLight);
    flex-grow: 1;
    font-size: 1.25rem;
    line-height: 1rem;
    padding: 0.5rem;
    vertical-align: middle;

    &::placeholder {
        color: var(--textLight);
    }

    &:focus, &:hover {
        background: var(--backgroundLightest);

        outline: none;
    }
}
.fieldWrapper {
    display: flex;
    flex-direction: row;
}
.hasError {
    flex-direction: column;
}
.fieldError {
    border-color: var(--fail);
    color: var(--fail);
}
.fieldSuccess {
    border-color: var(--success);
    color: var(--success);
}
textarea.field {
    height: 5rem;
    max-width: 100%;
}

.error {
    color: var(--fail);
    font-size: 1rem;
    line-height: 2rem;
}

.success {
    color: var(--success);
    flex-shrink: 1;
    line-height: 2.75rem;
    padding: 0 0 0 1rem;
    transition: var(--transitionTime);
    position: absolute;
    right: 3rem;
    vertical-align: middle;
}