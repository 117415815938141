@import url('../../config.css');

.root {
    display: grid;
    grid-column-gap: 6rem;
    grid-template-columns: repeat(2, 1fr);
   grid-template-rows: repeat(3, 100px);
    margin: 10rem auto 0 auto;
    padding: 0;
    width: 100%;    

    @media (--viewportMedium) {
        width: var(--maximumViewWidth);
    }
}

.option {
    list-style-type: none;
    display: inline-block;

    a { 
        border: 1px solid var(--textLight);
        border-radius: 0.5rem;
        box-shadow: 2px 5px 10px var(--textLight);
        min-height: 24rem;
        display: block;
        text-decoration: none;
    }

    a:hover {
        box-shadow: 2px 5px 10px var(--textMedium);
    }

    span {
        background: rgba(255, 255, 255, 0.85);
        display: block;
        color: var(--textDark);
        font-size: 2rem;
        font-weight: var(--fontWeightBold);
        line-height: 100%;
        padding: 1rem;
        width: 100%;
    }
}

.caravan {
    background: url("../../assets/services/caravan.jpg") center center no-repeat;
    background-size: cover;
}

.hotTub {
    background: url("../../assets/services/hottub.jpg") center center no-repeat;
    background-size: cover;
}