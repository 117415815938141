@import url('../../config.css');

.root {
    background-size: cover;
    background-position: bottom center;
    max-height: 800px;
    display: none;
    flex-direction: row-reverse;
    height: 100vw;
    padding: 0 calc((100vw - var(--maximumViewWidth)) / 2);

    @media (--viewportMedium) {
        display: flex;
    }
}

.heroCaravan {
    background: url('../../assets/services/caravan.jpg') center bottom no-repeat;
}
.heroHotTub {
    background: url('../../assets/services/hottub.jpg') center bottom no-repeat;
    background-size: cover;
}


.textBox {
    align-self: flex-end;
    background: rgba(255, 255, 255, 0.85);
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    max-width: 800px;
    padding: 2rem;
    transition: var(--transitionTime);

    &:hover {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    }
}
.headline {
    color: var(--textDark);
    font-size: 3rem;
    line-height: 100%;
    width: 100%;
}
.subHeadline {
    font-size: 2rem;
    font-weight: var(--fontWeightRegular);
    text-align: right;
}