.brand {
    background: var(--white);
    border-bottom: 0.75rem solid var(--white);
    border-top: 0.75rem solid var(--white);
    display: flex;
    flex-direction: row;
    margin-left: 1.5rem;
    position: relative;
    z-index: calc(var(--zIndexTop) + 3);

    @media (--viewportMedium) {
        border-bottom: 0;
        border-top: 0;
        margin-left: 0;
    }
}

.brandName {
    color: var(--brandColor);
    display: inline-block;
    font-family: 'Pacifico', cursive;
    font-size: 2rem;
    letter-spacing: 2px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.5),
        2px 2px rgba(0, 0, 0, 0.4),
        3px 3px rgba(0, 0, 0, 0.3),
        4px 4px rgba(0, 0, 0, 0.2),
        5px 5px rgba(0, 0, 0, 0.1);

    @media (--viewportMedium) {
        font-size: 2.5rem;
    }

}