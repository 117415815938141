@import url('../../config.css');

.root {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    line-height: 2rem;
}

.type {
    width: 50%;

    @media (--viewportMedium) {
        width: 30%;
    }
}

.description {
    margin: 0;
    text-align: right;
    width: 50%;

    @media (--viewportMedium) {
        text-align: left;
        width: 70%;
    }
}